import { Outlet } from 'react-router-dom';
import { Container, Card } from 'react-bootstrap';
import './../mainStyle.scss';
import { IoHeartSharp } from 'react-icons/io5';
import { GiCoffeeCup } from 'react-icons/gi';

function Credits() {
  return (
    <>
      <Outlet />

      <Container className="txtCenter">
        <h2>
          This platform is built with <IoHeartSharp /> and <GiCoffeeCup /> in
          Italy.
        </h2>

        <br />

        <Card className="creditsCard mx-auto">
          <Card.Header>FLORENC DEMROZI</Card.Header>
          <Card.Body>
            <a href="https://sites.google.com/view/florencdemrozi">website</a>
            <hr />
            <p>
              Currently an Associate Professor in Biomedical Engineering at the
              Department of Electrical Engineering and Computer Science,
              University i Stavanger, Norway, working on Human Activity
              Recognition (HAR), Ambient Assisted Living (AAL), Internet of
              Medical Things (IoMT), Sensors and Measurements.
            </p>
          </Card.Body>
        </Card>

        <br />

        <Card className="creditsCard mx-auto">
          <Card.Header>Valide</Card.Header>
          <Card.Body>
            <i>
              We are focused on digital innovation for corporations and
              businesses
              <br />
              <a href="https://www.valide.no/en/home">valide.no</a>
            </i>
            <hr />
            <div className="row">
              <div className="col">
                <p>
                  Innovasjonspark Stavanger | <br />
                  Via Provina 38123
                  <br /> Professor Olav Hanssensvei 7A,
                  <br /> Tel.: 51 87 40 00 <br /> 4021 Stavanger, Postboks 8034,
                  4068 Stavanger <br />
                  E-post: post@valide.no
                </p>
              </div>
              <div className="col">
                <img
                  src={require('./../assets/valide.png')}
                  alt="DIGIT-ATOMS"
                  className="creditsImg"
                />
              </div>
            </div>
          </Card.Body>
        </Card>

        <br />

        <Card className="creditsCard mx-auto">
          <Card.Header>DIGIT-ATOMS</Card.Header>
          <Card.Body>
            <i>
              We are focused on digital innovation for corporations and
              businesses
              <br />
              <a href="https://www.digit-atoms.it">digit-atoms.it</a>
            </i>
            <hr />
            <div className="row">
              <div className="col">
                <p>
                  TRENTO (ITALY) <br />
                  Via Provina 38123
                  <br /> tel. 0461 1637568 | cel. 366 5944518
                  <br />
                  P.IVA 02655450225
                </p>
              </div>
              <div className="col">
                <img
                  src={require('./../assets/digit-atoms.png')}
                  alt="DIGIT-ATOMS"
                  className="creditsImg"
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default Credits;
