let SERVER_URL = 'http://127.0.0.1:8181/sosc/api';
let SOCKET_URL = 'ws://127.0.0.1:8181/sosc/websocket';

if (process.env.REACT_APP_BACKEND) {
  SERVER_URL = process.env.REACT_APP_BACKEND;
}
if (process.env.REACT_APP_BACKENDWS) {
  SOCKET_URL = process.env.REACT_APP_BACKENDWS;
}

// Export the variables using module.exports
module.exports = {
  SERVER_URL,
  SOCKET_URL,
};
